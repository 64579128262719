import * as React from "react";
import { useCallback, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// components
import CategoryHeader from "../components/categoryheader";
import HeadTitle from "../components/headtitle";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TestimonialSideBar from "../components/testimonial-sidebar";

// types & interfaces
import type {
  GatsbyImageType,
  SEO,
  Testimonial,
  PageProps,
} from "../lib/types";

interface TestimonialPageProps {
  title: string;
  SEO: SEO;
  cover: { localFile: GatsbyImageType };
}

interface TestimonialPageData {
  testimonials: { all: Testimonial[] };
}

const TestimonialPage: React.FC<
  PageProps<TestimonialPageProps, TestimonialPageData>
> = ({
  data: {
    page: { SEO, title, cover },
    testimonials: { all: testimonials },
  },
  location: { pathname },
}) => {
  const [selected, setSelected] = useState(testimonials[0]);
  const reformatedTestimonials = useCallback(
    (testimonials: Testimonial[]) =>
      testimonials.map(
        ({
          id,
          title,
          postalCode,
          clientSection: { images, projectType, clients },
          brokerSection: { name, isAMan },
        }) => ({
          id,
          title,
          image: images[0],
          postalCode,
          brokerName: name,
          isAWomanBroker: !isAMan,
          projectType,
          clientNames: clients.map(({ name }) => name),
        })
      ),
    []
  );

  return (
    <>
      <Layout active="#about">
        <Seo {...SEO} pathname={pathname} />
        <main className="mt-20 lg:mt-20">
          <header className={"hidden md:block"}>
            <CategoryHeader title={title} cover={cover} />
          </header>

          <div className="grid grid-cols-10 md:grid-cols-12 xl:grid-cols-11">
            <div
              className="col-span-2 pt-10 bg-light-50
              pb-48
              md:pb-60
              lg:col-span-4
              xl:col-span-3
              xl:pt-15"
            >
              <TestimonialSideBar
                className="sticky top-30 md:top-40"
                testimonials={reformatedTestimonials(testimonials)}
                selectedTestimonialId={selected.id}
                onChange={id => {
                  scroll({
                    top: document.querySelector("main").offsetTop - 50,
                    behavior: "smooth",
                  });
                  setSelected(
                    testimonials.find(testimonial => testimonial.id === id)
                  );
                }}
              />
            </div>

            <div
              className="space-y-8 col-span-8 pt-20
               pb-48
               md:pb-60
               md:col-span-10
               lg:col-span-8
               xl:col-span-8"
            >
              <ClientComment clientSection={selected.clientSection} />
              <BrokerComment
                brokerSection={selected.brokerSection}
                clientSection={selected.clientSection}
              />
              <Conditions conditionSection={selected.conditionSection} />
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestimonialPage;

const ClientComment: React.FC<Pick<Testimonial, "clientSection">> = ({
  clientSection: {
    comment,
    images,
    projectType,
    testimonial,
    propertyType,
    clients,
  },
}) => {
  /**
   * Get the correct article for the name :
   * Ex:
   *  - Audrey => d'[Audrey]
   *  - Frederic => de [Frederic]
   * */
  const getNameWithArticle = useCallback(({ name }: { name: string }) => {
    return `${name.toLowerCase().search(/[aeiou]/) == 0 ? "d'" : "de "}${name}`;
  }, []);

  /**
   * Filter the list et get only the name of the clients who talked
   * Ex:
   *  - [{ name: 'Antoine', hasTalked: false }, { name: 'Audrey', hasTalked: true }]
   *    will return => 'Audrey'
   */
  const hasTalked = useCallback(
    (clients: Array<{ name: string; hasTalked: boolean }>) => {
      return clients.filter(({ hasTalked }) => hasTalked);
    },
    []
  );

  /**
   * Get the section title formatted differently according to
   * the number of clients who has talked.
   * Ex :
   *  - If Audrey & Antoine Talked then the title will be :
   *     "Témoignage d'Audrey et Antoine suite à leur projet immobilier avec Monemprunt.com"
   *
   *  - If Only Audrey talked then :
   *     "Témoignage d'Audrey suite à son projet immobilier avec Monemprunt.com"
   * */
  const titleSection = `Témoignage 
  ${hasTalked(clients).map(getNameWithArticle).join(" et ")} suite à ${
    hasTalked.length === 1 ? "son" : "leur"
  } projet immobilier avec Monemprunt.com`;

  return (
    <section className="flex flex-col space-y-4">
      {/* Comment */}
      <div className="flex px-5 flex-col md:px-10 xl:px-15 lg:flex-row lg:justify-between">
        <HeadTitle
          as="h2"
          thin
          label={comment}
          className="italic mt-4 text-xl md:text-2xl lg:mt-0"
        />

        <div
          className="order-first
          inline-block
          max-w-max
          lg:order-last
         "
        >
          <div
            className="shadow-md text-white -rotate-3 -skew-x-3 bg-primary-100 px-1
          italic"
          >
            LE CLIENT
          </div>
        </div>
      </div>

      <div className="italic px-5 mt-2 text-sm md:text-base md:px-10 xl:px-15">
        {titleSection}
      </div>

      {/* Testimonial */}
      <article
        dangerouslySetInnerHTML={{ __html: testimonial }}
        className="mb-10 px-5 article__MEC md:px-10 xl:px-15"
      />

      <div className="grid gap-10 px-5 md:px-10 xl:px-15 md:grid-cols-2 xl:grid-cols-4">
        {/* Client names */}
        <div
          className="italic text-center
           text-sm
           flex flex-col
           items-center justify-center
           xl:order-last
           "
        >
          <div className="font-bold text-base md:text-lg">
            {clients.map(({ name }) => `${name}`).join(" & ")}
          </div>
          <div>{clients.map(({ clientAge }) => clientAge).join(" & ")} ans</div>
          <div>{propertyType}</div>
          <div>{projectType}</div>
        </div>

        {/* Images */}
        {images.map((image, index) => (
          <React.Fragment key={index}>
            <GatsbyImage
              image={getImage(image?.localFile.childImageSharp)}
              alt={"image Projet"}
              className="object-cover w-full h-60 rounded-md shadow-center xl:h-48 xl:w-48 xxl:h-64 xxl:w-64"
            />
          </React.Fragment>
        ))}
      </div>
      <div className="bg-gradient-to-b from-white to-gray-100 h-10 md:h-15" />
    </section>
  );
};

const BrokerComment: React.FC<
  Pick<Testimonial, "brokerSection" | "clientSection">
> = ({
  brokerSection: { comment, name, testimonial, image },
  clientSection: { clients },
}) => {
  /**
   * Get the correct article for the name :
   * Usage:
   *  - getNameWithArticle("Audrey") will return "d'Audrey"
   *  - getNameWithArticle("Frederic") will return "de Frederic"
   * */
  const getNameWithArticle = useCallback(({ name }: { name: string }) => {
    return `${name.toLowerCase().search(/[aeiou]/) == 0 ? "d'" : "de "}${name}`;
  }, []);

  return (
    <section className="flex flex-col space-y-4">
      <div className="flex px-5 flex-col md:px-10 xl:px-15 lg:flex-row lg:justify-between">
        <HeadTitle
          as="h2"
          thin
          label={comment}
          className="italc mt-4 text-xl md:text-2xl lg:mt-0"
        />

        <div
          className="order-first
          inline-block
          max-w-max
          lg:order-last
         "
        >
          <div
            className="shadow-md text-white -rotate-3 -skew-x-3 bg-secondary-100
            px-1 italic"
          >
            LE COURTIER
          </div>
        </div>
      </div>

      <div className="italic px-5 mt-2 text-sm md:text-base md:px-10 xl:px-15">
        Réactions et Remarques de <b className="uppercase">{name}</b> suite à
        son travail sur le projet &nbsp;
        <b>
          {getNameWithArticle({
            name: clients.map(({ name }) => name).join(" & "),
          })}
        </b>
        &nbsp;!
      </div>

      <div className="grid px-5 md:px-10 gap-4 xl:px-15 lg:grid-cols-12">
        <div className="hidden lg:block lg:col-span-3">
          <GatsbyImage
            image={getImage(image?.localFile.childImageSharp)}
            alt={`Photo de ${name}`}
            className="rounded-md"
          />
        </div>
        <article
          dangerouslySetInnerHTML={{ __html: testimonial }}
          className="article__MEC lg:col-span-9"
        />
      </div>
    </section>
  );
};

const Conditions: React.FC<Pick<Testimonial, "conditionSection">> = ({
  conditionSection: { amount, months },
}) => (
  <section
    className="grid grid-cols-2 gap-4 justify-between italic px-5
      md:px-10
      xl:px-15
      xl:grid-cols-4
      "
  >
    <HeadTitle
      as="h2"
      thin
      label="Les conditions négociées par monemprunt.com :"
      className="uppercase text-xl col-span-2"
    />

    <div className="flex flex-col text-center">
      <div className="font-bold text-lg xl:text-xl">{amount}</div>
      <div>euros par mois</div>
    </div>

    <div className="flex flex-col text-center">
      <div className="font-bold text-lg xl:text-xl">{months}</div>
      <div>mois</div>
    </div>
  </section>
);

export const query = graphql`
  query pageAvisClient {
    testimonials: allStrapiAvisClient {
      all: nodes {
        id: strapiId
        title
        postalCode
        conditionSection {
          amount
          months
        }
        clientSection {
          projectType
          propertyType
          comment
          clients {
            name
            clientAge
            hasTalked: talked
          }
          testimonial
          images {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
        brokerSection {
          comment
          isAMan
          testimonial
          name
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
    page: strapiPageAvisClients {
      title
      SEO {
        description
        slug
        title
      }
      cover {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;
