import * as React from "react";
import { GatsbyImageType, Testimonial } from "../lib/types";
import TestimonialCard from "./testimonial-card";

type PartialTestimonial = Pick<Testimonial, "id" | "title" | "postalCode"> & {
  image: { localFile: GatsbyImageType };
  projectType: string;
  brokerName: string;
  clientNames: string[];
  isAWomanBroker: boolean;
};

export interface TestimonialSideBarProps {
  testimonials: Array<PartialTestimonial>;
  className?: string;
  onChange?: (testimonialId: number) => void;
  selectedTestimonialId: number;
}

const TestimonialSideBar: React.FC<TestimonialSideBarProps> = ({
  className = "",
  onChange,
  selectedTestimonialId,
  testimonials,
}) => (
  <aside className={`${className} bg-light-50 p-2 space-y-8 md:p-4`}>
    <div className="font-bold hidden md:block md:text-sm lg:text-base">
      NOS CAS CLIENTS
    </div>
    <nav>
      <ul className="space-y-4">
        {testimonials.map(
          (
            {
              id,
              title,
              postalCode,
              image,
              projectType,
              brokerName,
              isAWomanBroker,
              clientNames,
            },
            i
          ) => {
            return (
              <li key={i}>
                <TestimonialCard
                  className="lg:w-full"
                  clientNames={clientNames}
                  selected={selectedTestimonialId === id}
                  onClick={() => onChange(id)}
                  brokerName={brokerName}
                  isAWomanBroker={isAWomanBroker}
                  postalCode={postalCode}
                  projectType={projectType}
                  title={title}
                  image={image}
                />
              </li>
            );
          }
        )}
      </ul>
    </nav>
  </aside>
);

export default TestimonialSideBar;
