import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { GatsbyImageType } from "../lib/types";
import Icon from "./icon";

export interface TestimonialCardProps {
  image: { localFile: GatsbyImageType };
  title: string;
  projectType: string;
  postalCode: number;
  brokerName: string;
  isAWomanBroker: boolean;
  onClick?: () => void;
  selected: boolean;
  className?: string;
  clientNames: string[];
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  image,
  title,
  postalCode,
  brokerName,
  isAWomanBroker,
  onClick,
  selected,
  className = "",
  clientNames,
}) => (
  <button
    aria-label={`Lire le témoignage de ${clientNames.join(" et ")}`}
    className={`flex flex-col p-0 shadow-center-dark rounded-md cursor-pointer bg-white  
    ${className}
    ${selected && "bg-gradient-to-r"}
    group  
    hover:bg-gradient-to-r 
    from-white to-gray-100     
    hover:to-gray-200
    focus-visible:focus:outline-black

    lg:grid
    lg:grid-cols-12
    h-full
    lg:h-[100px]
    xl:h-full
    `}
    onClick={() => onClick && onClick()}
  >
    {/* Image */}
    <div className="h-15 p-0 md:h-20 lg:h-full lg:col-span-3">
      {image.localFile.childImageSharp ? (
        <GatsbyImage
          image={getImage(image?.localFile.childImageSharp)}
          alt={title}
          className={`${!selected && "grayscale"} object-cover w-full h-full 
          rounded-tl-md rounded-tr-md rounded-b-md
          md:rounded-b-none
          lg:rounded-tr-none lg:rounded-r-none lg:rounded-l-md`}
        />
      ) : (
        <img
          src={image.localFile.publicURL}
          alt={title}
          className={`${!selected && "grayscale"} object-cover w-full h-full
          rounded-tl-md rounded-tr-md rounded-b-md 
          md:rounded-b-none
          lg:rounded-tr-none lg:rounded-r-none lg:rounded-l-md`}
        />
      )}
    </div>

    {/* Names */}
    <small className="text-center p-1 hidden text-xs md:block lg:hidden">
      {clientNames.join(" & ")}
    </small>

    {/* Title */}
    <div className="justify-between w-full hidden lg:flex lg:col-span-9 lg:items-center">
      <div className="flex flex-col py-2 px-4 text-left lg:justify-between">
        <div className="font-bold lg:text-base xl:text-lg">
          {clientNames.join(" & ")}
        </div>

        <div className="italic text-sm xxl:text-base">
          {title} ({postalCode})
        </div>
        <div className="italic text-sm xxl:text-base">
          Expert{isAWomanBroker && "e"} : {brokerName}
        </div>
      </div>
      <div
        className={`pr-2 font-bold group-hover:text-secondary-100 flex items-center justify-center ${
          selected && "text-secondary-100"
        } xl:pr-4`}
      >
        <Icon icon={"arrow-right"} className="h-5" />
      </div>
    </div>
  </button>
);

export default TestimonialCard;
